<template>
	<div class="fan">
		<Nav></Nav>
	</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
export default {
	name: 'Fan',
	components: {
		Nav
	},
	mounted() {
		document.body.style.overflow='hidden'
		setTimeout(() => {
			this.$store.commit('showLoading')
			document.body.style.overflow=''		
		},2000)
	},
}
</script>

<style>
</style>
